* {
  box-sizing: border-box;
  scrollbar-color: rgba(0, 0, 0, 0.12) #fff;
  scrollbar-width: thin;
}

::-webkit-scrollbar-button {
  width: 8px;
  height:0px
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
}

::-webkit-scrollbar{
  width: 8px;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html, #root {
  height: 100%;
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

.online-doc-tooltip {
  font-family: 'Roboto Condensed', Arial, sans-serif;
  font-size: 12px !important;
  line-height: 18px;
  border-radius: 6px !important;
  padding: 4px 8px !important;
  background-color: rgba(51,51,51,.96) !important;
  margin-top: 8px !important;
  z-index: 1002 !important;
}

.online-doc-tooltip::before, .online-doc-tooltip::after {
  display: none;
}


