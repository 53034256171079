/*@font-face {*/
/*    font-family: 'Roboto Condensed';*/
/*    src: url('./assets/fonts/RobotoCondensed-Regular.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Roboto Condensed';*/
/*    src: url('./assets/fonts/RobotoCondensed-Bold.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Roboto';*/
/*    src: url('./assets/fonts/Roboto-Medium.woff') format('woff');*/
/*    font-weight: 500;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Roboto';*/
/*    src: url('./assets/fonts/Roboto-Regular.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/RobotoCondensed-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/RobotoCondensed-Bold.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
